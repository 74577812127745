<template>
  <div>
    <template>
      <div class="d-flex justify-content-end mb-1">

        <b-button
          v-if="edit===true"
          variant="primary"
          class="mr-1"
          @click="editEmployeeBasicInformation"
        >EDIT
        </b-button>
        <b-button
          v-if="edit===false"
          variant="success"
          class="mr-1"
          @click="updateEmployee"
        >SAVE
        </b-button>
        <b-button
          v-if="edit===false"
          variant="danger"
          class="mr-1"
          @click="refresh"
        >CANCEL
        </b-button>
      </div>

      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%)!important;"
      >

        <template #header>
          <b-card-title class="title-uwu">
            PERSONAL INFORMATION
          </b-card-title>
        </template>
        <template #default>

          <validation-observer ref="form">
            <b-row>
              <!-- First Name -->
              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider
                  v-slot="{ errors,valid }"
                  name="First Name"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >First Name
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-form-input
                      v-if=" finishedLoading"
                      id="first-name"
                      v-model="userData.first_name"
                      name="first-name"
                      autofocus
                      :state="errors[0] ? false : valid ? true : null"
                      trim
                      :disabled="edit"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <!-- MiddleName -->
              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider name="MiddleName">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >Middle Name
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-form-input
                      v-if=" finishedLoading"
                      id="middle-name"
                      v-model="userData.middle_name"
                      trim
                      :disabled="edit"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <!-- LastName -->
              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider
                  v-slot="{ errors,valid }"
                  name="LastName"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >Last Name
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-form-input
                      v-if=" finishedLoading"
                      id="last-name"
                      v-model="userData.last_name"
                      trim
                      :state="errors[0] ? false : valid ? true : null"
                      :disabled="edit"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider name="KnowAs">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >Know As
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-form-input
                      v-if=" finishedLoading"
                      id="know-as"
                      v-model="userData.know_as"
                      trim
                      :disabled="edit"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <!-- DocType -->
              <b-col
                lg="6"
                class="mt-1"
              >

                <validation-provider
                  v-slot="{errors}"
                  name="DocType"
                  rules="required"
                >
                  <b-input-group
                    label-for="doc-type"
                    :state="errors[0] ? false : null"
                  >
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >Doc Type
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-select
                      v-if=" finishedLoading"
                      v-model="userData.pid_type"
                      :disabled="edit"
                      value-field="id"
                      text-field="name"
                      :options="document"
                    />

                  </b-input-group>
                </validation-provider>
              </b-col>
              <!-- NumberDoc -->
              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider
                  v-slot="{ errors,valid }"
                  name="NumberDoc"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>

                      <b-input-group-text
                        class="title_lable"
                      >Number Doc
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-form-input
                      v-if=" finishedLoading"
                      id="number-doc"
                      v-model="userData.pid"
                      trim
                      :state="errors[0] ? false : valid ? true : null"
                      :disabled="edit"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider

                  name="DOE"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >DOE
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <kendo-datepicker

                      v-if=" finishedLoading"

                      v-model="userData.doe"
                      v-mask="'##/##/####'"
                      :class="['w-auto rounded bg-transparent k-picker-custom  picker-select-date', {'text-white':isDarkSkin}, 'custom-caro2']"
                      :format="'MM/dd/yyyy'"

                      :disabled="edit"
                    />

                  </b-input-group>
                </validation-provider>
              </b-col>
              <!-- Nationality -->
              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider name="Nationality">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >Nationality
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-form-input
                      v-if=" finishedLoading"
                      id="know-as"
                      v-model="userData.nationality"
                      trim
                      :disabled="edit"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <!-- DOB -->
              <b-col
                lg="6"
                class="mt-1"
              >
                <validation-provider name="DOB">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >DOB
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <kendo-datepicker
                      v-if=" finishedLoading"
                      v-model="userData.dob"
                      v-mask="'##/##/####'"
                      :class="['w-auto rounded bg-transparent k-picker-custom  picker-select-date', {'text-white':isDarkSkin}, 'custom-caro2']"
                      :format="'MM/dd/yyyy'"
                      :disabled="edit"
                    />

                  </b-input-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="6"
                class="mt-1"
              >
                <!-- Gender-->
                <validation-provider
                  name="Gender"
                  rules="required"
                >
                  <b-input-group
                    label="Gender"
                    label-for="gender"
                  >
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >Gender
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-select
                      v-if=" finishedLoading"
                      v-model="userData.gender"
                      :disabled="edit"
                      value-field="gender"
                      text-field="name"
                      :options="optionsgender"
                    />

                  </b-input-group>
                </validation-provider>

              </b-col>
              <b-col
                lg="6"
                class="mt-1"
              >
                <!-- Martial-->
                <validation-provider name="Martial ">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >Martial
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-select
                      v-if=" finishedLoading"
                      v-model="userData.marital_id"
                      :disabled="edit"
                      value-field="id"
                      text-field="name"
                      :options="martial"
                    />

                  </b-input-group>
                </validation-provider>

              </b-col>

            </b-row>
          </validation-observer>
        </template>
      </b-card>
    </template>

    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%)!important;"
      >
        <template #header>
          <b-card-title class="title-uwu">
            CONTACT INFORMATION
          </b-card-title>
        </template>
        <template #default>
          <b-row>
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <!-- Mailing  Address -->
              <validation-provider name="MailingAddress">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Mailing Address
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="mailing-address"
                    v-model="userData.address"
                    name="mailing-address"
                    autofocus

                    trim
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <!-- City -->
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <validation-provider name="City">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >City
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="city"
                    v-model="userData.city"
                    trim
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <!-- State -->
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <validation-provider name="State">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >State
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="tate"
                    v-model="userData.state"
                    trim
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <!-- Zip -->
              <validation-provider name="Zip Code">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Zip Code
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="zip-code"
                    v-model="userData.zip_code"
                    name="zip-code"
                    autofocus
                    :disabled="edit"
                    trim
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <!-- Country -->
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <validation-provider name="Country">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Country
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="country"
                    v-model="userData.country"
                    trim
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <!-- Home Phone -->
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <validation-provider name="HomePhone">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Home Phone
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="home-phone"
                    v-model="userData.home_phone"
                    type="number"
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <!-- Mobile Phone -->
              <validation-provider name="Mobile Phone">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Mobile Phone
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="mobile-phone"
                    v-model="userData.mobile"

                    v-mask="'###-###-###'"
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <!-- Work Phone -->
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <validation-provider name="Work Phone">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Work Phone
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="work-phone"
                    v-model="userData.work_phone"
                    v-mask="'(XXX)-XXX-XXXX'"
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <!-- Work Email -->
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <validation-provider
                v-slot="{ errors }"
                name="WorkEmail"
                rules="email"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Work Email
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="work-email"
                    v-model="userData.work_email"
                    type="email"
                    :disabled="edit"
                    :state="errors[0] ? false : null"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
            <b-col
              lg="6"
              md="4"
              class="mt-1"
            >
              <validation-provider name="PrivateEmail">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >Private Email
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="private-email"
                    v-model="userData.private_email"
                    trim
                    type="email"
                    :disabled="edit"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </template>

    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%)!important;"
      >
        <template #header>
          <b-card-title class="title-uwu">
            EMERGENCY CONTACT
          </b-card-title>
        </template>
        <template #default>
          <b-row
            v-for="(data,index) in userData.contacts"
            :key="index"
          >
            <b-col>
              <b-row class="mb-1">
                <b-col lg="4">
                  <!-- Contact  Name -->
                  <validation-provider name="ContactName">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                        >Contact Name
                        </b-input-group-text>
                      </template>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="input"
                      />
                      <b-form-input
                        v-if=" finishedLoading"
                        id="contact-name1"
                        v-model="data.name"
                        name="contact-name1"
                        :disabled="edit"
                        type="text"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-col>
                <!-- MiddleName -->
                <b-col
                  lg="4"
                  md="6"
                >
                  <validation-provider name="Contact Phone">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                        >Contact Phone
                        </b-input-group-text>
                      </template>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="input"
                      />
                      <b-form-input
                        v-if=" finishedLoading"
                        id="contact-phone1"
                        v-model="data.phone"
                        type="text"
                        :disabled="edit"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-col>
                <!-- LastName -->
                <b-col lg="4">

                  <validation-provider name="Relation">
                    <b-input-group
                      label="Relation"
                      label-for="relation1"
                    >
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                        >Relation
                        </b-input-group-text>
                      </template>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="input"
                      />

                      <b-select
                        v-if=" finishedLoading"
                        v-model="data.relation_id"
                        :disabled="edit"
                        value-field="id"
                        text-field="name"
                        :options="relations"
                      />

                    </b-input-group>
                  </validation-provider>
                </b-col>
              </b-row>

            </b-col>

          </b-row>

        </template>
      </b-card>
    </template>

    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%)!important;"
      >
        <template #header>
          <b-card-title class="title-uwu">
            ACADEMIC INFORMATION
          </b-card-title>
        </template>
        <template #default>
          <b-row v-if="!userData.academic_information">
            <b-col cols="12">
              <h5 class="text-primary ml-1">
                Doesn't have academic information
              </h5>
            </b-col>
          </b-row>

          <b-row
            v-for="(item,index) in JSON.parse(userData.academic_information)"
            v-else
            :key="index"
            class="mb-1"
          >
            <b-col lg="4">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_lable"
                  >Level
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space flex-grow-1"
                  type="input"
                />
                <b-form-input
                  v-if=" finishedLoading"
                  id="level"
                  name="contact-name1"
                  disabled
                  type="text"
                  placeholder="Level"
                  :value="item.level"
                />
              </b-input-group>
            </b-col>
            <b-col
              lg="4"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_lable"
                  >University / Institute
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space flex-grow-1"
                  type="input"
                />
                <b-form-input
                  v-if=" finishedLoading"
                  id="contact-phone1"
                  type="text"
                  disabled
                  placeholder="UNIVERSITY / INSTITUTE"
                  :value="item.institute_university"
                />
              </b-input-group>
            </b-col>
            <b-col lg="4">
              <b-input-group
                label="Relation"
                label-for="relation1"
              >
                <template #prepend>
                  <b-input-group-text
                    class="title_lable"
                  >Career
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space flex-grow-1"
                  type="input"
                />

                <b-form-input
                  v-if=" finishedLoading"
                  id="contact-phone1"
                  type="text"
                  disabled
                  placeholder="CAREER"
                  :value="item.career"
                />
              </b-input-group>
            </b-col>
          </b-row>

        </template>
      </b-card>
    </template>

    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%)!important;"
      >
        <template #header>
          <b-card-title class="title-uwu">
            PENSION FUND
          </b-card-title>
        </template>
        <template #default>
          <b-row v-if="!userData.status_pension_fund">
            <b-col cols="12">
              <h5 class="text-primary ml-1">
                Doesn't have pension fund
              </h5>
            </b-col>
          </b-row>
          <b-row v-else>
            <template v-if="!userData.current_pension_fund">
              <b-col
                v-if="userData.old_pension_fund"
                cols="12"
                md="6"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >
                      Old Pension Fund
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="level"
                    name="contact-name1"
                    disabled
                    type="text"
                    placeholder="Old Pension Fund"
                    :value="userData.old_pension_fund"
                  />
                </b-input-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >
                      New Pension Fund
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="level"
                    name="contact-name1"
                    disabled
                    type="text"
                    placeholder="Old Pension Fund"
                    :value="userData.new_pension_fund"
                  />
                </b-input-group>
              </b-col>
            </template>
            <template v-else>
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >
                      Current Pension Fund
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if=" finishedLoading"
                    id="level"
                    name="contact-name1"
                    disabled
                    type="text"
                    placeholder="Old Pension Fund"
                    :value="userData.current_pension_fund"
                  />
                </b-input-group>
              </b-col>
            </template>
            <b-col
              cols="12"
              class="mt-2"
            >
              <strong
                :class="colorPension[userData.status_pension_fund]"
              >{{ userData.status_pension_fund }}</strong>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </template>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import { mapGetters, mapState } from 'vuex';
import { createPopper } from '@popperjs/core';
import EmployeesService from '@/views/management/views/employees/employees.service';

export default {
  components: {

    vSelect,

  },
  data() {
    return {
      optionsgender: [{ gender: '1', name: 'Female' }, { gender: '2', name: 'Male' }],

      userData: { file: '' },
      finishedLoading: false,
      relations: [],
      department: [],
      martial: [],
      document: [],
      image: null,
      optionsRol: [],
      edit: true,
      userDataRefresh: [],
      colorPension: {
        AFFILIATED: 'text-success',
        PENDING: 'text-warning',
      },
    };
  },
  computed: {

    ...mapGetters({
      currentUser: 'auth/currentUser',

    }),

  },
  mounted() {
    this.getAllDocuments();
    this.getAllMartial();
    this.getAllRelations();
  },
  async created() {
    await this.getInformationEmployeer();
    this.finishedLoading = true;
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },

    async getAllRelations() {
      try {
        const data = await EmployeesService.getAllRelations();

        // eslint-disable-next-line no-param-reassign

        this.relations = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.relations;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllDocuments() {
      try {
        const data = await EmployeesService.getAllDocuments();

        // eslint-disable-next-line no-param-reassign

        this.document = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.document;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllMartial() {
      try {
        const data = await EmployeesService.getAllMartial();

        // eslint-disable-next-line no-param-reassign

        this.martial = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.martial;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async editEmployeeBasicInformation() {
      this.edit = !this.edit;
    },

    async updateEmployee() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            const params = {
              id: this.$route.params.id,
              first_name: this.userData.first_name,
              middle_name: this.userData.middle_name,
              last_name: this.userData.last_name,
              know: this.userData.know_as,
              dtype: this.userData.pid_type,

              dnumber: this.userData.pid,
              nationality: this.userData.nationality,
              dob: this.userData.dob,
              gender: this.userData.gender,

              address: this.userData.address,
              city: this.userData.city,
              state: this.userData.state,
              zip: this.userData.zip_code,

              country: this.userData.country,

              phone: this.userData.home_phone,
              pmobile: this.userData.mobile,
              pwork: this.userData.work_phone,
              ework: this.userData.work_email,
              eprivate: this.userData.private_email,

              cname1: this.userData.contacts[0].name ? this.userData.contacts[0].name : ' ',
              cphone1: this.userData.contacts[0].phone ? this.userData.contacts[0].phone : ' ',
              crelation1: this.userData.contacts[0].relation_id ? this.userData.contacts[0].relation_id : ' ',

              cname2: this.userData.contacts[1].name ? this.userData.contacts[1].name : ' ',
              cphone2: this.userData.contacts[1].phone ? this.userData.contacts[1].phone : ' ',
              crelation2: this.userData.contacts[1].relation_id ? this.userData.contacts[1].relation_id : ' ',
              user_id: this.currentUser.user_id,
              martial: this.userData.marital_id,
              doe: this.userData.doe,
            };

            const data = await EmployeesService.updateEmployee(params);
            if (data.status === 200) {
              this.refresh();
              await this.getInformationEmployeer();

              this.showSuccessSwal();
              await this.$store.dispatch('ManagementEmployeesStore/A_EMPLOYEE_INFO', this.$route.params.id);
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    refresh() {
      this.edit = true;
      this.userData = { ...this.userDataRefresh };
    },

    async getInformationEmployeer() {
      try {
        const params = {
          id: this.$route.params.id,
        };
        const data = await EmployeesService.getEmployeeInformation(params);

        // eslint-disable-next-line no-param-reassign

        this.userData = data.data[0];

        // Must return an array of items or an empty array if an error occurred

        data.data.map(data => {
          // eslint-disable-next-line no-param-reassign
          data.contacts = JSON.parse(data.contacts);

          if (data.contacts == null) {
            data.contacts = [
              {
                id: null,
                name: null,
                phone: null,
                relation: null,
                relation_id: null,
              },
              {
                id: null,
                name: null,
                phone: null,
                relation: null,
                relation_id: null,
              },
            ];
          }
          if (data.contacts.length == 1) {
            data.contacts = [
              {
                id: data.contacts[0].id,
                name: data.contacts[0].name,
                phone: data.contacts[0].phone,
                relation: data.contacts[0].relation,
                relation_id: data.contacts[0].relation_id,
              },
              {
                id: null,
                name: null,
                phone: null,
                relation: null,
                relation_id: null,
              },
            ];
          }
        });

        this.userDataRefresh = { ...this.userData };

        return this.userData;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },

};
</script>

<style>
.space{
  width: auto;
}
.truncate {
  width: 75.5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

#overpx .vs__dropdown-toggle .vs__selected-options .vs__search {
  margin: 9.15px 0 0 !important;
}

.title-uwu {
  font-weight: bold !important;
}

@media (max-width: 1366px) {
  .space {
    width: 44% !important;

  }

  .title_lable {
    width: 183px;
  }
}
.custom-caro.k-input{
  max-width: 218px !important;
  width: 300px !important;
}

.custom-caro2.k-input{
  max-width: 322px !important;
  width: 322px !important;
}

</style>
